import { i18n } from "@lingui/core";
export default function getProductFilters() {
  return {
    aggregations: {
      highlights: {
        title:
        /*i18n*/
        {
          id: 'Highlights'
        },
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: function get(product) {
          var _product$highlights, _product$highlights$v, _product$appellationM;

          var highlights = ((_product$highlights = product.highlights) === null || _product$highlights === void 0 ? void 0 : (_product$highlights$v = _product$highlights.values) === null || _product$highlights$v === void 0 ? void 0 : _product$highlights$v.map(function (highlight) {
            return (highlight === null || highlight === void 0 ? void 0 : highlight.replace('#', ' ')) || '';
          })) || [];
          var amw = (_product$appellationM = product.appellationMarlborough) !== null && _product$appellationM !== void 0 && _product$appellationM.value ? 'Appellation Marlborough Wine' : undefined;
          return [// Exclude 'None'
          ...highlights.filter(function (highlight) {
            return highlight !== 'None';
          }), amw].filter(Boolean);
        } // Excludes nulls

      },
      productType: {
        title:
        /*i18n*/
        {
          id: 'Product types'
        },
        conjunction: false,
        get: function get(product) {
          return product.wineType || // product.spiritType ||
          // product.beerType ||
          product.productType;
        },
        size: 20,
        translations: {
          WINE:
          /*i18n*/
          {
            id: 'Other wine'
          },
          SPIRIT:
          /*i18n*/
          {
            id: 'Spirit'
          },
          'Still wine':
          /*i18n*/
          {
            id: 'Wine'
          },
          BEER:
          /*i18n*/
          {
            id: 'Beer and more'
          }
        }
      },
      country: {
        title:
        /*i18n*/
        {
          id: 'Countries'
        },
        conjunction: false,
        multiSelect: false,
        size: 100,
        get: function get(product) {
          return product.country || undefined;
        } // Excludes nulls

      },
      regionHierarchy: {
        title:
        /*i18n*/
        {
          id: 'Winegrowing regions'
        },
        conjunction: false,
        // multiSelect: false,
        size: 100,
        get: function get(product) {
          var _product$regionHierar;

          return ((_product$regionHierar = product.regionHierarchy) === null || _product$regionHierar === void 0 ? void 0 : _product$regionHierar.map(function (e) {
            return e || '';
          })) || [];
        } // Excludes nulls

      },
      vintage: {
        title:
        /*i18n*/
        {
          id: 'Vintages'
        },
        conjunction: false,
        size: 50,
        sort: 'term',
        order: 'desc',
        get: function get(product) {
          return product.vintage || undefined;
        },
        // Excludes nulls
        translations: {
          NV:
          /*i18n*/
          {
            id: 'NV'
          }
        }
      },
      priceRanges: {
        title:
        /*i18n*/
        {
          id: 'Price ranges'
        },
        conjunction: false,
        sort: 'term',
        // This comes from ProductsTemplate.afterQuery.
        get: function get(product) {
          var _product$pricing, _product$pricing2, _product$pricing3, _product$pricing4, _product$pricing9, _product$pricing14;

          if (!((_product$pricing = product.pricing) !== null && _product$pricing !== void 0 && _product$pricing.priceStrategy)) return undefined; // Excludes nulls

          switch ((_product$pricing2 = product.pricing) === null || _product$pricing2 === void 0 ? void 0 : _product$pricing2.priceStrategy) {
            case 'BY_PRICE':
              if (!((_product$pricing3 = product.pricing) !== null && _product$pricing3 !== void 0 && _product$pricing3.price)) return undefined; // Excludes nulls

              if (((_product$pricing4 = product.pricing) === null || _product$pricing4 === void 0 ? void 0 : _product$pricing4.currencyCode) === 'GBP') {
                var _product$pricing5, _product$pricing6, _product$pricing7, _product$pricing8;

                if (((_product$pricing5 = product.pricing) === null || _product$pricing5 === void 0 ? void 0 : _product$pricing5.price) < 10) return '£';
                if (((_product$pricing6 = product.pricing) === null || _product$pricing6 === void 0 ? void 0 : _product$pricing6.price) < 15) return '££';
                if (((_product$pricing7 = product.pricing) === null || _product$pricing7 === void 0 ? void 0 : _product$pricing7.price) < 20) return '£££';
                if (((_product$pricing8 = product.pricing) === null || _product$pricing8 === void 0 ? void 0 : _product$pricing8.price) < 30) return '££££';
                return '£££££';
              } else if (((_product$pricing9 = product.pricing) === null || _product$pricing9 === void 0 ? void 0 : _product$pricing9.currencyCode) === '$') {
                var _product$pricing10, _product$pricing11, _product$pricing12, _product$pricing13;

                if (((_product$pricing10 = product.pricing) === null || _product$pricing10 === void 0 ? void 0 : _product$pricing10.price) < 14) return '$';
                if (((_product$pricing11 = product.pricing) === null || _product$pricing11 === void 0 ? void 0 : _product$pricing11.price) < 21) return '$$';
                if (((_product$pricing12 = product.pricing) === null || _product$pricing12 === void 0 ? void 0 : _product$pricing12.price) < 28) return '$$$';
                if (((_product$pricing13 = product.pricing) === null || _product$pricing13 === void 0 ? void 0 : _product$pricing13.price) < 42) return '$$$$';
                return '$$$$$';
              }

            case 'BY_PRICE_RANGE':
              return ((_product$pricing14 = product.pricing) === null || _product$pricing14 === void 0 ? void 0 : _product$pricing14.priceRange) || undefined;
            // Excludes nulls
          }
        }
      },
      productionMethods: {
        title:
        /*i18n*/
        {
          id: 'Production methods'
        },
        conjunction: false,
        size: 20,
        get: function get(product) {
          var _product$characterist;

          return (product === null || product === void 0 ? void 0 : (_product$characterist = product.characteristics) === null || _product$characterist === void 0 ? void 0 : _product$characterist.filter(function (value) {
            return ['VEGAN', 'ORGANIC', 'VEGETARIAN', 'BIODYNAMIC', 'KOSHER', 'SUSTAINABLE'].includes(value || '');
          }).map(function (e) {
            return e || '';
          })) || [];
        },
        translations: {
          VEGAN:
          /*i18n*/
          {
            id: 'vegan'
          },
          ORGANIC:
          /*i18n*/
          {
            id: 'organic'
          },
          VEGETARIAN:
          /*i18n*/
          {
            id: 'vegetarian'
          },
          BIODYNAMIC:
          /*i18n*/
          {
            id: 'biodynamic'
          },
          KOSHER:
          /*i18n*/
          {
            id: 'kosher'
          },
          SUSTAINABLE:
          /*i18n*/
          {
            id: 'sustainable'
          }
        }
      },
      classification: {
        title:
        /*i18n*/
        {
          id: 'Classifications'
        },
        conjunction: false,
        size: 50,
        get: function get(product) {
          if (!(product !== null && product !== void 0 && product.classification)) return undefined; // Excludes nulls

          if (product.classification === '----') return undefined;
          if (product.classification === 'No classification') return undefined;
          return product.classification;
        }
      },
      specialClassification: {
        title:
        /*i18n*/
        {
          id: 'Special classifications'
        },
        conjunction: false,
        size: 50,
        get: function get(product) {
          if (!(product !== null && product !== void 0 && product.specialClassification)) return undefined; // Excludes nulls

          if (product.specialClassification === '----') return undefined;
          return product.specialClassification;
        }
      },
      designation: {
        title:
        /*i18n*/
        {
          id: 'Designations'
        },
        conjunction: false,
        size: 50,
        get: function get(product) {
          return product.designation || undefined;
        } // Excludes nulls

      },
      grapeVarieties: {
        title:
        /*i18n*/
        {
          id: 'Grape varieties'
        },
        conjunction: false,
        size: 300,
        get: function get(product) {
          var _product$grapeVarieti;

          return (_product$grapeVarieti = product.grapeVarieties) === null || _product$grapeVarieti === void 0 ? void 0 : _product$grapeVarieti.map(function (variety) {
            return (variety === null || variety === void 0 ? void 0 : variety.varietyName) || '';
          }).filter(Boolean);
        } // Excludes nulls

      },
      closureTypes: {
        title:
        /*i18n*/
        {
          id: 'Closure types'
        },
        conjunction: false,
        size: 50,
        get: function get(product) {
          var _product$bottleVarian, _product$bottleVarian2;

          return ((_product$bottleVarian = product.bottleVariants) === null || _product$bottleVarian === void 0 ? void 0 : (_product$bottleVarian2 = _product$bottleVarian[0]) === null || _product$bottleVarian2 === void 0 ? void 0 : _product$bottleVarian2.closureType) || undefined;
        } // Excludes nulls

      }
    },
    sortings: {
      byProducerName: {
        title:
        /*i18n*/
        {
          id: 'Producer name'
        },
        field: [function (product) {
          var _product$producer, _product$producer2;

          return (product === null || product === void 0 ? void 0 : (_product$producer = product.producer) === null || _product$producer === void 0 ? void 0 : _product$producer.sortName) || (product === null || product === void 0 ? void 0 : (_product$producer2 = product.producer) === null || _product$producer2 === void 0 ? void 0 : _product$producer2.name);
        }],
        order: 'asc',
        group: 'producer.producerId',
        groupHeader: 'producer'
      },
      byProductName: {
        title:
        /*i18n*/
        {
          id: 'Product name'
        },
        field: ['shortName', 'vintage'],
        order: ['asc', 'desc']
      },
      bySortIndex: {
        title:
        /*i18n*/
        {
          id: 'Sort number'
        },
        field: ['sortIndex'],
        order: ['asc']
      } // byExhibitorName: {
      //   title: defineMessage({ message: 'Exhibitor name' }),
      //   field: [
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'exhibitorId',
      // },
      // byStandNumber: {
      //   title: defineMessage({ message: 'Table number' }),
      //   field: [
      //     sortBy.standName,
      //     'exhibitor.sortName',
      //     'exhibitor.name',
      //     'producer.sortName',
      //     'producer.name',
      //   ],
      //   order: 'asc',
      //   group: 'registration.registrationId',
      // },

    }
  };
} // Sort helper functions

var sortBy = {
  standName: function standName(product) {
    var _product$stand;

    return (product === null || product === void 0 ? void 0 : (_product$stand = product.stand) === null || _product$stand === void 0 ? void 0 : _product$stand.name.split(/[\s,-]+/).map(function (segment) {
      return segment === 'Tisch' ? undefined : Number(segment) ? segment.padStart(3, '0') : segment;
    }).filter(Boolean).join(' ')) || '999';
  }
};
var fragment = "1958873893";