// @ts-check
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
} from '@bottlebooks/gatsby-theme-base';
import { defineMessage } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { graphql as gatsbyGraphQL } from 'gatsby';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { jsx } from 'theme-ui';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import EmptyState from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/EmptyState';
import ActiveFiltersBar from '@bottlebooks/gatsby-theme-event/src/components/Filters/ActiveFiltersBar';
import FilterField from '@bottlebooks/gatsby-theme-event/src/components/Filters/FilterField';
import {
  filtersCleared,
  filterToggled,
  searchTermChanged,
  sortChanged,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/filterReducer';
import Filters, {
  useFiltersDisplay,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Filters';
// eslint-disable-next-line import/no-unresolved
import useIndex from '@bottlebooks/gatsby-theme-event/src/components/Filters/useIndex';
import useLocationSyncedState from '@bottlebooks/gatsby-theme-event/src/components/Filters/useLocationSyncedState';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import GroupedProductsCard from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/GroupedProductsCard';
import ProductCard from '@bottlebooks/gatsby-theme-event/src/components/ProductCard/ProductCard';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo';
// import DownloadMyBookmarks from './DownloadMyBookmarks';
// eslint-disable-next-line import/no-unresolved
import getProductFilters from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/productFilters';
import ProductsPageTitle from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/ProductsPageTitle';
import Sort from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/Sort';
import useSearch from '@bottlebooks/gatsby-theme-event/src/components/ProductsPage/useProductSearch';

const PAGE_SIZE = 20;

export default function ProductsPage({
  products,
  location,
  locale,
  hasMasterList,
}) {
  const { i18n } = useLingui();
  const [state, dispatch] = useLocationSyncedState(location);
  const filterConfig = getProductFilters();
  const searched = useSearch(products, state.searchTerm);
  const sortings = Object.entries(filterConfig.sortings)
    .map(([key, sorting]) => ({ key, ...sorting }))
    .filter((sorting) => {
      const isSortIndex = sorting.key === 'bySortIndex';
      return hasMasterList ? isSortIndex : !isSortIndex;
    });
  const activeSorting =
    sortings.find((sorting) => sorting.key === state.sort) || sortings[0];
  const sort = activeSorting.key;
  const index = useIndex(searched, filterConfig);
  const [limit, setLimit] = React.useState(PAGE_SIZE);

  const filters = Object.fromEntries(
    Object.keys(filterConfig.aggregations).map((key) => [key, state[key] || []])
  );

  const { data, pagination } = index.search({ per_page: limit, sort, filters });
  // console.log(sort, filterConfig, data, pagination, timings);

  const { getToggleProps, getFiltersProps, isVisibleOnDesktop } =
    useFiltersDisplay();

  function toggleFilter(aggregation, bucket) {
    const definition = filterConfig.aggregations[aggregation.name];
    const multiSelect = definition?.multiSelect ?? !definition?.conjunction;
    dispatch(filterToggled(aggregation.name, bucket.key, !multiSelect));
    setLimit(PAGE_SIZE);
  }

  const mainColumn = {
    gridColumnStart: [1, isVisibleOnDesktop ? 2 : 1],
    gridColumnEnd: -1,
  };
  return (
    <Layout locale={locale}>
      <SEO
        page={{ path: location.href }}
        title={i18n._(defineMessage({ message: 'Products' }))}
      />
      <Box sx={{ backgroundColor: 'light' }}>
        <Container
          fluid
          sx={{ paddingTop: 3, paddingBottom: 6 }}
          // sx={{ maxWidth: 'container.fluid' }}
        >
          <Stack>
            <ActiveFiltersBar
              searchTerm={state.searchTerm}
              aggregations={data.aggregations}
              definitions={filterConfig.aggregations}
              onToggleFilter={toggleFilter}
              onClearFilters={() => dispatch(filtersCleared())}
            />
            <Flex
              gap={2}
              align="baseline"
              justify="space-between"
              sx={{ flexDirection: ['column', 'row'] }}
            >
              <ProductsPageTitle
                totalCount={products.length}
                count={pagination.total}
              />
              {/* <DownloadMyBookmarks
                collectionId={collectionId}
                locale={locale}
              /> */}
            </Flex>

            <Grid
              width={316}
              repeat="fill"
              sx={{ alignItems: 'center', gridAutoFlow: 'dense' }}
            >
              <Sort
                sortings={sortings}
                value={sort}
                onChange={(value) => dispatch(sortChanged(value))}
                sx={{ gridColumn: '-2/-1', textAlign: 'right' }}
              />
              <FilterField
                placeholder={i18n._(
                  defineMessage({ message: 'Enter wine or producer name' })
                )}
                value={state.searchTerm}
                onChange={(value) => {
                  setLimit(PAGE_SIZE);
                  return dispatch(searchTermChanged(value));
                }}
                variant="large"
                sx={{
                  borderColor: 'borderSecondary',
                  gridColumn: ['1/-1', '2/-2'],
                }}
              />
              <Filters.ToggleDesktop {...getToggleProps()} />
              <Filters.ToggleMobile {...getToggleProps()} />
            </Grid>

            <Grid width={316} repeat="fill" sx={{ alignItems: 'flex-start' }}>
              <Filters
                key={state.searchTerm}
                aggregations={data.aggregations}
                definitions={filterConfig.aggregations}
                onToggleFilter={toggleFilter}
                totalCount={products.length}
                count={pagination.totalCount}
                {...getFiltersProps()}
                sx={{
                  maxHeight: [null, '100vh'],
                  overflowY: [null, 'auto'],
                  position: [null, 'sticky'],
                  top: 0,
                }}
              />
              {!pagination.total ? (
                <EmptyState sx={mainColumn} />
              ) : (
                <MainList
                  width={300}
                  items={data.items}
                  group={activeSorting.group}
                  groupHeader={activeSorting.groupHeader}
                  limit={limit}
                  setLimit={setLimit}
                  pagination={pagination}
                  sx={{ gridAutoFlow: 'dense', ...mainColumn }}
                />
              )}
            </Grid>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
}

/** @typedef {{ id: string; productId: string; exhibitorId?: string; producerId?: string; registration: { companyId?: string; } }} Product */
/**
 * @param {{
 * items: Product[];
 * group?: any;
 * groupHeader?: 'exhibitor' | 'producer' | 'room';
 * pagination: { total: number;  per_page: number; page: number; };
 * limit: number;
 * setLimit: (set: number | ((limit: number) => number)) => void;
 * width?: number;
 * }} props
 * */
function MainList({
  items,
  group,
  groupHeader = 'exhibitor',
  pagination,
  limit,
  setLimit,
  width,
  ...rest
}) {
  const link = useLink();
  const hasMore = pagination.total > items?.length;
  const showMore = React.useCallback(
    () => setLimit((/** @type {number} */ limit) => limit + PAGE_SIZE),
    [setLimit]
  );

  if (Boolean(group))
    return (
      <ProductsList
        hasMore={hasMore}
        showMore={showMore}
        width={width}
        {...rest}
      >
        {Object.entries(groupBy(items, group)).map(([key, products]) => (
          <GroupedProductsCard
            key={key}
            exhibitorId={products[0]?.registration?.companyId}
            products={products}
            // groupedBy={products.length === 1 ? 'hidden' : 'exhibitor'}
            groupedBy={groupHeader}
            sx={{
              marginBottom: 4,
              gridColumn: products.length <= 2 ? [null, 'span 2'] : '1/-1',
            }}
          />
        ))}
      </ProductsList>
    );
  return (
    <ProductsList hasMore={hasMore} showMore={showMore} width={width} {...rest}>
      {items.map((item) => (
        <ProductCard key={item.id} product={item} to={link.product(item)} />
      ))}
    </ProductsList>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ProductsPageBase on Bottlebooks_RegisteredProduct {
    registration {
      registrationId
      companyId
      stand {
        name
      }
      profile {
        sortName
        name
      }
    }
    ...productFilters
    product {
      productId
      ...bb_GroupedProductsCard
      ...bb_ProductRegion
      ...bb_Search_Product
    }
    # ...useLink_bb_Product # TODO This doesn't work because exhibitorId isn't resolved correctly on RegisteredProduct.product.
  }

  fragment bb_ProductsPageBase_Collection on Bottlebooks_Collection {
    ...bb_ProductFilters_Event
  }

  fragment ProductsPage on Product {
    id
    features # The features have to be queried outside of the list row because they are not typically shown.
    ...ProductListRow
    ...ProductCard
    ...useLink_Product # TODO exhibitorId isn't available on RegisteredProduct.product, so we need to make sure it comes from Product at least.
  }

  fragment ProductsPage_first on Product {
    ...ProductListRow_first
    ...ProductListRow
    ...ProductCard_first
    ...ProductCard
  }

  fragment ProductsPage_Event on Event {
    ...EventBanner
  }

  fragment FieldValue on Bottlebooks_FieldValue {
    ... on Bottlebooks_TextFieldValue {
      text: value
    }
    ... on Bottlebooks_IntFieldValue {
      integer: value
    }
    ... on Bottlebooks_FloatFieldValue {
      number: value
    }
    ... on Bottlebooks_YesNoFieldValue {
      value
      rawText
    }
    ... on Bottlebooks_SelectFieldValue {
      text: value
    }
    ... on Bottlebooks_MultiSelectFieldValue {
      values: value(format: LOCALIZED)
    }
    ... on Bottlebooks_ChangeoverFieldValue {
      month: value
    }
  }
`;
